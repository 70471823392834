@import "~FRONTEND/css/_variables.scss";
//
//
// nurminen.dev website
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//


@charset "utf-8";

@import './font-faces.scss';
@import './_variables.scss';


// Import Bulma and Buefy styles 
@import "~bulma";
@import "~@ntohq/buefy-next/src/scss/buefy";

@import '~bulma-modal-fx/dist/css/modal-fx.css';


//
// Site-wide styling
//
html,
body {
	height: 100%;
    background-color: #F6F6F6;
    font-size: $body-size;
}


@media screen and (max-width: 1023px) {
    .container { margin: 0 10px; }

    .hero { .container { margin: 0 auto; } }
}


a,
a:link,
a:visited,
a:active {
    color: $text;
    text-decoration: none;
}

:not(strong) > a { border-bottom: 2px solid #ff7c00; }

strong > a { border-bottom: 3px solid #ff7c00; }

a:hover {
    text-decoration: none;
    border-bottom: none;
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}


// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
  }