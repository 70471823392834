@import "~FRONTEND/css/_variables.scss";


@media screen and (min-width: 1400px) {
  section.hero { border-radius: 0 0 50% 50% / 0 0 6% 6%; }
}

@media screen and (max-width: 1399px) {
  section.hero { border-radius: 0 0 50% 50% / 0 0 3% 3%; }
}



section.hero {
  background-color: #3d0066;
  padding: 50px 0;
  background-image: url('../images/bg2.jpg');
  background-size: cover;
  padding: 40px 0 20px 0;
  margin-bottom: 40px;

  a { border-bottom: none; }

  .hero-body {
    h1, h2 {
      color: #f6f6f6;
      filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
    }

    h1 { font-weight: 800; }

    h2 {
      font-family: 'Manus';
      font-size: 2.5rem;
      font-weight: 400;
    }

    figure.image {
      display: inline-block;

      .is-rounded {
        border: 3px solid #ff7c00;
        padding: 2px;
      }

      &.is-150x150 {
        width: 250px;
        height: 250px;
      }

      img { filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5)); }
    }

    .full-stack-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;

      .node-js-logo {
        margin-top: 7px;
      }

      .plus-icon {
        color: #fff;
        font-size: 60px;
        font-weight: 800;
        line-height: 60px;
        margin-top: 7px;
        margin-left: 20px;
        margin-right: 3px;
      }

      .node-js-logo img,
      .plus-icon,
      .vue-js-logo img {
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
      }
    }

  }
}

@media screen and (max-width: 600px) {
  section.hero {
    .hero-body {
      h1 { font-size: 1.7rem; }
      h2 { font-size: 2.2rem; }
    }
  }
}


section.page-content {
  .collapse-container {
    .collapse-toggler {
      display: flex;
      font-family: 'Manus';
      font-size: 3rem;
      padding-left: 50px;

      &.collapsed {
        background: url('../images/arrow-chevron-right.svg') 0 10px no-repeat;
        background-size: 40px;
      }

      &.expanded {
        background: url('../images/arrow-chevron-down.svg') 0 10px no-repeat;
        background-size: 40px;
      }
    }

    .collapse-content {
      margin-left: 35px;
      margin-bottom: 30px;

      h3.title {
        font-size: 1.3rem;
        margin-bottom: 5px;
      }

      ul {
        list-style-type: disc;
        margin-left: 25px;

        > li {
          font-size: 1.125rem;

          > ul {
            list-style-type: circle;
            margin-left: 20px;

            > li {
              font-size: 1rem;
            }
          }
        }
      }

      @media screen and (max-width: 1023px) {
        ul {
          margin-left: 20px;
        }
      }


      .image-gallery {
        display: flex;
        flex-wrap: wrap;

        justify-content: center;
        align-items: center;

        gap: 20px;

        margin-bottom: 25px;
      }

      .collapse-section {
        background: url('../images/arrow-chevron-up.svg') 0 4px no-repeat;
        background-size: 30px;
        font-family: Manus;
        font-size: 1.7rem;
        padding-left: 40px;

      }

    }

    @media screen and (max-width: 1023px) {
      .collapse-content {
        margin-left: 10px;
      }
    }


  }

}


section.contact {
  margin-bottom: 40px;

  h2 {
    font-family: 'Manus';
    font-size: 3rem;
  }

  .contact-columns {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;
    align-items: flex-start;

    gap: 10px;

    margin-bottom: 25px;

    .business-contact {
      background-color: #fff;
      border: 1px solid gray;

      border-radius: 5px;
      padding: 10px;
    }

  }
}


footer {
  color: #f6f6f6;

  display: flex;

  justify-content: center;

  background-color: #3d0066;
  padding: 50px 0;
  background-image: url('../images/bg2.jpg');
  background-size: cover;
  padding: 45px 0 40px 0;

  > div {
        flex-basis:content;
        text-align: center;

  }
}

@media screen and (min-width: 1400px) {
  footer { border-radius: 50% 50% 0% 0% / 16% 16% 0% 0%; }
}

@media screen and (max-width: 1399px) {
  footer { border-radius: 50% 50% 0% 0% / 16% 16% 0% 0%; }
}

