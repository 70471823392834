//
//
// nurminen.dev website
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//


$body-size:                     16px;
$family-sans-serif:             "Open Sans", sans-serif;
$text:                          #3b3b3b;
$primary:                       #D01D19;
$grey:                          rgb(56, 40, 40);
